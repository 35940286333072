* {
  color: white;
}

#main-div-coming-soon-container{
  height: 100vh;
  width: 100vw;
  background-color: black;
  /* border: 1px solid blue; */
}

#main-div-coming-soon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* border: 1px solid orange; */
}

#gestalt-text{
  display: flex;
  font-size: 7vw;
  justify-content: center;
  padding-left: 3.5vh;
  /* border: 1px solid purple; */
}

#coming-soon-gestalt-text {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  float: left;
}

#coming-soon-random-char {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-style: normal;
  width: 40px;
  color: rgba(255, 255, 255, 0.434);
  float: right;
  /* border: 1px solid red; */
}

#coming-soon-text{
  height: 4vh;
  animation: focus 4s;
  font-size: 3.3vh;
  font-family: vgFont;
  /* border: 1px solid red; */
}
#coming-soon-text-placeholder{
  height: 4vh;
  font-size: 3.3vh;
  font-family: vgFont;

}

@keyframes focus {
  0% {
    opacity: 100;
    filter: blur(7px);
    text-shadow: none;
  }
  5% {
    opacity: 0;
    filter: blur(7px);
    text-shadow: none;
  }
  12% {
    opacity: 100;
    filter: blur(7px);
    text-shadow: none;
  }
  20% {
    opacity: 0;
    filter: blur(7px);
    font-size: 3.4vh;
    text-shadow: none;
  }
  30% {
    font-size: 3.6vh;
    filter: blur(9px);
    text-shadow:
        0 0 12px rgba(255, 255, 255, 0.519),
        0 0 22px rgba(255, 255, 255, 0.583),
        0 0 32px rgba(255, 255, 255, 0.555),
        0 0 32px rgba(255, 255, 255, 0.434),
        0 0 36px rgb(255, 255, 255);
  }
  /* 40% {
    opacity: 90;
    filter: blur(7px);
    font-size: 3.4vh;
  } */
  40% {
    opacity: 100;
    filter: blur(2px);
    font-size: 3.4vh;
    text-shadow:
        0 0 2px rgba(255, 255, 255, 0.519),
        0 0 2px rgba(255, 255, 255, 0.583),
        0 0 2px rgba(255, 255, 255, 0.555),
        0 0 2px rgba(255, 255, 255, 0.434),
        0 0 2px rgb(255, 255, 255);
  }
  45% {
    opacity: 100;
    filter: blur(2px);
    font-size: 3.4vh;
    text-shadow:
        0 0 2px rgba(255, 255, 255, 0.519),
        0 0 2px rgba(255, 255, 255, 0.583),
        0 0 2px rgba(255, 255, 255, 0.555),
        0 0 2px rgba(255, 255, 255, 0.434),
        0 0 2px rgb(255, 255, 255);
  }
  50% {
    opacity: 100;
    filter: blur(4px);
    font-size: 3.4vh;
    text-shadow:
      0 0 2px rgba(255, 255, 255, 0.519),
      0 0 2px rgba(255, 255, 255, 0.583),
      0 0 2px rgba(255, 255, 255, 0.555),
      0 0 2px rgba(255, 255, 255, 0.434),
      0 0 2px rgb(255, 255, 255);
  }
  /* 50% {
    opacity: 100;
    filter: blur(6px);
    font-size: 3.4vh;
    text-shadow: none;
  } */
  65% {
    opacity: 100;
    filter: blur(2px);
    font-size: 3.4vh;
    text-shadow:
        0 0 2px rgba(255, 255, 255, 0.519),
        0 0 2px rgba(255, 255, 255, 0.583),
        0 0 2px rgba(255, 255, 255, 0.555),
        0 0 2px rgba(255, 255, 255, 0.434),
        0 0 2px rgb(255, 255, 255);
  }
  76% {
    opacity: 100;
    filter: blur(14px);
    /* text-shadow: none; */
    text-shadow:
        0 0 12px rgba(255, 255, 255, 0.519),
        0 0 22px rgba(255, 255, 255, 0.583),
        0 0 32px rgba(255, 255, 255, 0.555),
        0 0 32px rgba(255, 255, 255, 0.434),
        0 0 36px rgb(255, 255, 255);
  }
  77% {
    opacity: 0;
    filter: blur(14px);
    font-size: 3.3vh;
    /* text-shadow: none; */
    text-shadow:
        0 0 12px rgba(255, 255, 255, 0.519),
        0 0 22px rgba(255, 255, 255, 0.583),
        0 0 32px rgba(255, 255, 255, 0.555),
        0 0 32px rgba(255, 255, 255, 0.434),
        0 0 36px rgb(255, 255, 255);
  }
  78% {
    opacity: 100 ;
    filter: blur(16px);
    font-size: 3.6vh;
    /* text-shadow: none; */
    text-shadow:
        0 0 12px rgba(255, 255, 255, 0.519),
        0 0 22px rgba(255, 255, 255, 0.583),
        0 0 32px rgba(255, 255, 255, 0.555),
        0 0 32px rgba(255, 255, 255, 0.434),
        0 0 36px rgb(255, 255, 255);
  }
  90% {
    filter: none;
    font-size: 3.3vh;
    color: #fff;
    text-shadow:
      0 0 2px rgba(255, 255, 255, 0.519),
      0 0 2px rgba(255, 255, 255, 0.583),
      0 0 2px rgba(255, 255, 255, 0.555),
      0 0 2px rgba(255, 255, 255, 0.434),
      0 0 2px rgb(255, 255, 255);
  }
  100% {
    font-size: 3.3vh;
    color: #fff;
    text-shadow:
      0 0 2px rgba(255, 255, 255, 0.519),
      0 0 2px rgba(255, 255, 255, 0.583),
      0 0 2px rgba(255, 255, 255, 0.555),
      0 0 2px rgba(255, 255, 255, 0.434),
      0 0 2px rgb(255, 255, 255);
  }
}

#email-symbol{
  height: 1.5vh;
  margin-right: .6vh;
  padding-left: 1.5vh;
}

#email-text-and-cursor{
  /* border: 1px solid orange; */
  margin-top: 10vh;
  text-shadow:
      0 0 .5px rgba(255, 255, 255, 0.519),
      0 0 .5px rgba(255, 255, 255, 0.583),
      0 0 .5px rgba(255, 255, 255, 0.555),
      0 0 .5px rgba(255, 255, 255, 0.434),
      0 0 .5px rgb(255, 255, 255);
}

#email-cursor{
  font-size: 2.8vh;
  height: fit-content;
  width: 4px;
  font-family: vgFont;
  /* border: 1px solid rgb(213, 74, 98); */
}

#email-text{
  font-family: vgFont;
  font-size: 2.4vh;
  font-weight: 600;
  letter-spacing: 3px;
  /* border: 1px solid purple; */
}

@font-face {
  font-family: vgFont;
  src: url('./vg_font.ttf');
}

@font-face {
  font-family: randomChar;
  src: url('./RandomChar-Regular.ttf');
}

.neon-text {
  color: #fff;
  text-shadow:
    0 0 2px rgba(255, 255, 255, 0.519),
    0 0 2px rgba(255, 255, 255, 0.583),
    0 0 2px rgba(255, 255, 255, 0.555),
    0 0 2px rgba(255, 255, 255, 0.434),
    0 0 2px rgb(255, 255, 255);
    /* 0 0 22px rgba(0, 255, 170, 0.42),
    0 0 42px rgba(0, 255, 170, 0.306),
    0 0 52px rgba(0, 255, 170, 0.334),
    0 0 52px rgba(0, 255, 170, 0.357),
    0 0 81px rgba(0, 255, 170, 0.4); */
  letter-spacing: 2px;
}

@media (max-width: 824px){
  #gestalt-text{
    font-size: 6.8vw;
    margin-left: 1vh;
  }
}

@media (max-width: 500px){
  #gestalt-text{
    font-size: 6.4vw;
    margin-left: 1.4vh;
  }
  #email-text {
    font-size: 1.7vh;
  }

  #coming-soon-text {
    font-size: 2.3vh;
  }

  @keyframes focus {
    0% {
      opacity: 100;
      filter: blur(3.5px);
      text-shadow: none;
    }

    5% {
      opacity: 0;
      filter: blur(3.5px);
      text-shadow: none;
    }

    12% {
      opacity: 100;
      filter: blur(3.5px);
      text-shadow: none;
    }

    20% {
      opacity: 0;
      filter: blur(3.5px);
      font-size: 2.4vh;
      text-shadow: none;
    }

    30% {
      font-size: 2.6vh;
      filter: blur(4.5px);
      text-shadow:
        0 0 6px rgba(255, 255, 255, 0.519),
        0 0 11px rgba(255, 255, 255, 0.583),
        0 0 16px rgba(255, 255, 255, 0.555),
        0 0 16px rgba(255, 255, 255, 0.434),
        0 0 18px rgb(255, 255, 255);
    }

    /* 40% {
    opacity: 90;
    filter: blur(7px);
    font-size: 3.4vh;
  } */
    40% {
      opacity: 100;
      filter: blur(1px);
      font-size: 2.4vh;
      text-shadow:
        0 0 1px rgba(255, 255, 255, 0.519),
        0 0 1px rgba(255, 255, 255, 0.583),
        0 0 1px rgba(255, 255, 255, 0.555),
        0 0 1px rgba(255, 255, 255, 0.434),
        0 0 1px rgb(255, 255, 255);
    }

    45% {
      opacity: 100;
      filter: blur(1px);
      font-size: 2.4vh;
      text-shadow:
        0 0 1px rgba(255, 255, 255, 0.519),
        0 0 1px rgba(255, 255, 255, 0.583),
        0 0 1px rgba(255, 255, 255, 0.555),
        0 0 1px rgba(255, 255, 255, 0.434),
        0 0 1px rgb(255, 255, 255);
    }

    50% {
      opacity: 100;
      filter: blur(2px);
      font-size: 2.4vh;
      text-shadow:
        0 0 1px rgba(255, 255, 255, 0.519),
        0 0 1px rgba(255, 255, 255, 0.583),
        0 0 1px rgba(255, 255, 255, 0.555),
        0 0 1px rgba(255, 255, 255, 0.434),
        0 0 1px rgb(255, 255, 255);
    }

    /* 50% {
    opacity: 100;
    filter: blur(6px);
    font-size: 3.4vh;
    text-shadow: none;
  } */
    65% {
      opacity: 100;
      filter: blur(1px);
      font-size: 2.4vh;
      text-shadow:
        0 0 1px rgba(255, 255, 255, 0.519),
        0 0 1px rgba(255, 255, 255, 0.583),
        0 0 1px rgba(255, 255, 255, 0.555),
        0 0 1px rgba(255, 255, 255, 0.434),
        0 0 1px rgb(255, 255, 255);
    }

    76% {
      opacity: 100;
      filter: blur(7px);
      /* text-shadow: none; */
      text-shadow:
        0 0 6px rgba(255, 255, 255, 0.519),
        0 0 11px rgba(255, 255, 255, 0.583),
        0 0 16px rgba(255, 255, 255, 0.555),
        0 0 16px rgba(255, 255, 255, 0.434),
        0 0 18px rgb(255, 255, 255);
    }

    77% {
      opacity: 0;
      filter: blur(7px);
      font-size: 2.3vh;
      /* text-shadow: none; */
      text-shadow:
        0 0 6px rgba(255, 255, 255, 0.519),
        0 0 11px rgba(255, 255, 255, 0.583),
        0 0 16px rgba(255, 255, 255, 0.555),
        0 0 16px rgba(255, 255, 255, 0.434),
        0 0 18px rgb(255, 255, 255);
    }

    78% {
      opacity: 100;
      filter: blur(8px);
      font-size: 2.6vh;
      /* text-shadow: none; */
      text-shadow:
        0 0 6px rgba(255, 255, 255, 0.519),
        0 0 11px rgba(255, 255, 255, 0.583),
        0 0 16px rgba(255, 255, 255, 0.555),
        0 0 16px rgba(255, 255, 255, 0.434),
        0 0 18px rgb(255, 255, 255);
    }

    90% {
      filter: none;
      font-size: 2.3vh;
      color: #fff;
      text-shadow:
        0 0 1px rgba(255, 255, 255, 0.519),
        0 0 1px rgba(255, 255, 255, 0.583),
        0 0 1px rgba(255, 255, 255, 0.555),
        0 0 1px rgba(255, 255, 255, 0.434),
        0 0 1px rgb(255, 255, 255);
    }

    100% {
      font-size: 2.3vh;
      color: #fff;
      text-shadow:
        0 0 1px rgba(255, 255, 255, 0.519),
        0 0 1px rgba(255, 255, 255, 0.583),
        0 0 1px rgba(255, 255, 255, 0.555),
        0 0 1px rgba(255, 255, 255, 0.434),
        0 0 1px rgb(255, 255, 255);
    }
  }
}
